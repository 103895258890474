import React from "react";
import { IconContext } from "react-icons";
import { FcGlobe, FcFolder, FcDocument } from "react-icons/fc";

const SecTotal = ({ totalBerita, totalPage, totalKategori }) => {
  return (
    <div className="p-4">
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-white shadow rounded-lg p-4">
          <div className="flex flex-row">
            <IconContext.Provider value={{ size: 20 }}>
              <FcGlobe />
            </IconContext.Provider>
            <h4 className="flex-1 ml-2 font-poppins-regular text-slate-500 text-sm">
              Total Berita
            </h4>
          </div>

          <h1 className="font-poppins-semibold text-black text-[36px] mt-2">
            {totalBerita}
          </h1>
        </div>
        <div className="bg-white shadow rounded-lg p-4">
          <div className="flex flex-row">
            <IconContext.Provider value={{ size: 20 }}>
              <FcFolder />
            </IconContext.Provider>
            <h4 className="flex-1 ml-2 font-poppins-regular text-slate-500 text-sm">
              Total Kategori
            </h4>
          </div>

          <h1 className="font-poppins-semibold text-black text-[36px] mt-2">
            {totalKategori}
          </h1>
        </div>
        <div className="bg-white shadow rounded-lg p-4">
          <div className="flex flex-row">
            <IconContext.Provider value={{ size: 20 }}>
              <FcDocument />
            </IconContext.Provider>
            <h4 className="flex-1 ml-2 font-poppins-regular text-slate-500 text-sm">
              Total Page
            </h4>
          </div>

          <h1 className="font-poppins-semibold text-black text-[36px] mt-2">
            {totalPage}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default SecTotal;
