import React from "react";

const TextArea = ({ label, name, set, val, placeholder, rows }) => {
  return (
    <>
      <label
        htmlFor={name}
        className="block font-poppins-regular text-xs leading-6 text-gray-400"
      >
        {label}
      </label>
      <div className="mt-1">
        <textarea
          id={name}
          name={name}
          onChange={(e) => set(e.target.value)}
          rows={rows}
          value={val}
          className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 focus:bg-gray-50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-poppins-regular"
          placeholder={placeholder}
        />
      </div>
    </>
  );
};

export default TextArea;
