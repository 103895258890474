import React from "react";
import { NavLink } from "react-router-dom";
import {
  HiOutlineInbox,
  HiFolderOpen,
  HiUserGroup,
  HiNewspaper,
  HiStatusOnline,
  HiDocumentText,
} from "react-icons/hi";
import LogoPayolebar from "../../assets/images/logo-payolebarnews.png";

const Sidebar = () => {
  const menu = [
    { name: "Home", icon: <HiOutlineInbox size={22} />, route: "/" },
    { name: "Kategori", icon: <HiFolderOpen size={22} />, route: "/kategori" },
    { name: "Page", icon: <HiDocumentText size={22} />, route: "/page" },
    { name: "Editor", icon: <HiUserGroup size={22} />, route: "/editor" },
    { name: "Berita", icon: <HiNewspaper size={22} />, route: "/berita" },
    { name: "Iklan", icon: <HiStatusOnline size={22} />, route: "/iklan" },
  ];

  return (
    <div className="fixed z-20 h-screen border-r border-gray-200 w-64 px-6 bg-black">
      <img src={LogoPayolebar} className="w-40 mt-3" alt="logo" />
      <div className="text-lg font-poppins-semibold py-2 mb-6 text-gray-100">
        PAYOLEBAR NEWS
      </div>
      <div>
        <div className="mb-4 text-gray-100 text-md font-poppins-semibold">
          MAIN MENU
        </div>
        <ul className="space-y-4">
          {menu.map((val, index) => {
            return (
              <li
                key={index}
                className="flex flex-row items-center text-gray-200 font-poppins-medium text-md"
              >
                <div className="mr-4 rounded-md">{val.icon}</div>
                <NavLink
                  to={val.route}
                  className={({ isActive }) =>
                    isActive
                      ? "text-orange-300 font-poppins-semibold"
                      : "hover:text-orange-400"
                  }
                >
                  {val.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
