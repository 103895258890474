import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { API_URL, API_UPLOAD } from "../../config/index";
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import { FaSearch, FaSort } from "react-icons/fa";
import {
  BsFillPencilFill,
  BsFillTrashFill,
  BsFillCameraFill,
} from "react-icons/bs";

import { formatDate } from "../../function/DateFormat";

const Table = () => {
  const navigate = useNavigate();

  const [editor, setEditor] = useState([]);
  const [page, setPage] = useState(0);
  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [field, setField] = useState("namaPerusahaan");
  const [fieldQuery, setFieldQuery] = useState("namaPerusahaan");
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    // get refresh token
    checkToken();

    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldQuery, keyword, limit, page]);

  const checkToken = () => {
    const token = localStorage.getItem("tokenPL");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenPL");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const deleteData = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosJWT
          .delete(`${API_URL}/editor/${id}`)
          .then((response) => {
            getAllData();
            Swal.fire("Deleted!", response.data.message, "success");
          })
          .catch((error) => {
            Swal.fire("Deleted!", error.response.data.message, "error");
          });
      }
    });
  };

  const getAllData = async () => {
    await axiosJWT
      .get(
        `${API_URL}/editor?field_query=${fieldQuery}&search_query=${keyword}&page=${page}&limit=${limit}`
      )
      .then((response) => {
        setEditor(response.data.docs);
        setPage(response.data.page);
        setPages(response.data.totalPages);
        setRows(response.data.totalDocs);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const changePage = ({ selected }) => {
    setPage(selected + 1);
    if (selected === 9) {
      setMsg(
        "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
      );
    } else {
      setMsg("");
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
    setFieldQuery(field);
  };

  const resetPage = (e) => {
    e.preventDefault();
    setPage(0);
    setQuery("");
    setField("namaLengkap");
    setKeyword("");
    setFieldQuery("namaLengkap");
    setLimit(10);
  };

  return (
    <>
      <div className="px-2 mb-3">
        <div className="flex flex-row items-center">
          <div className="flex-1 mr-3">
            <form onSubmit={searchData}>
              <div className="flex">
                <label
                  htmlFor="search-dropdown"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only"
                >
                  Filter
                </label>
                <select
                  id="countries"
                  className="text-xs font-poppins-regular text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:outline-none focus:ring-gray-300 focus:border-blue-500"
                  value={field}
                  onChange={(e) => setField(e.target.value)}
                >
                  <option value="namaKategori">Nama Kategori</option>
                  <option value="descKategori">Deskripsi</option>
                </select>

                <div className="relative w-full">
                  <input
                    type="search"
                    id="search-dropdown"
                    className="block p-2 w-full z-20 font-poppins-regular text-xs text-gray-900 bg-gray-100 rounded-r-lg border-l-gray-100 border-l-2 border border-gray-300 focus:ring-gray-300 focus:border-blue-500"
                    placeholder="Ketik pencarian..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-[#15803d] hover:bg-[#15803d]/90 active:bg-green-600 active:text-gray-300 rounded-r-lg border border-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300"
                  >
                    <FaSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="flex-1 text-end">
            <button
              type="submit"
              className="text-black bg-[#F9FAFB] hover:bg-[#E5EDFF] active:bg-gray-100 active:text-gray-300 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 font-medium rounded-lg text-xs px-5 py-2 text-center font-poppins-regular inline-flex items-center mr-2 shadow-md"
              onClick={resetPage}
            >
              <span className="mr-2">
                <HiOutlineRefresh />
              </span>
              <span>Reload</span>
            </button>
            <Link
              to="add"
              className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg font-poppins-regular text-xs px-5 py-2 text-center inline-flex items-center mr-2 shadow-md"
            >
              <span className="mr-2">
                <HiPlus />
              </span>
              <span>Tambah</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="relative overflow-x-auto mb-3">
        <table className="w-full font-poppins-regular text-xs text-left text-gray-500 border-b">
          <thead className="text-xs border-b border-t text-gray-500 bg-gray-100">
            <tr>
              <th scope="col" className="p-2 border-r">
                #
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                Foto
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Nama Lengkap
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Group
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Inisial
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Slug
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Created At
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Updated At
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>

              <th scope="col" className="px-2 py-3">
                <span>Aksi</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {editor ? (
              <>
                {editor.map((val, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="p-2 border-r">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all-search"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          />
                          <label
                            htmlFor="checkbox-all-search"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td className="px-2 py-2 text-gray-900 border-r">
                        <img
                          src={`${API_UPLOAD}/editor/resize/x64/${val.fotoProfile}`}
                          alt={val.namaLengkap}
                          className="w-[32px] rounded-full"
                        />
                      </td>
                      <td className="px-2 py-2 text-gray-900 border-r">
                        {val.namaLengkap}
                      </td>
                      <td className="px-2 py-2 border-r">
                        {val.group === "Editor" ? (
                          <span className="bg-green-600 text-white px-3 py-1 text-xs text-center rounded-full">
                            {val.group}
                          </span>
                        ) : (
                          <span className="bg-orange-600 text-white px-3 py-1 text-xs text-center rounded-full">
                            {val.group}
                          </span>
                        )}
                      </td>
                      <td className="px-2 py-2 border-r">{val.initialName}</td>
                      <td className="px-2 py-2 border-r">{val.slugPenulis}</td>
                      <td className="px-2 py-2 border-r">
                        {formatDate(val.createdAt)}
                      </td>
                      <td className="px-2 py-2 border-r">
                        {formatDate(val.updatedAt)}
                      </td>
                      <td className="px-2 py-2 text-center border-r">
                        <div className="inline-flex flex-row">
                          <Link
                            to={`foto/${val._id}`}
                            className="font-medium text-gray-500 px-2 py-2 hover:bg-gray-200 hover:rounded-full"
                          >
                            <span>
                              <BsFillCameraFill />
                            </span>
                          </Link>
                          <Link
                            to={`${val._id}`}
                            className="font-medium text-gray-500 px-2 py-2 hover:bg-gray-200 hover:rounded-full"
                          >
                            <span>
                              <BsFillPencilFill />
                            </span>
                          </Link>

                          <button
                            type="button"
                            onClick={() => deleteData(val._id)}
                            className="font-medium text-gray-500 px-2 py-2 hover:bg-gray-200 hover:rounded-full"
                          >
                            <span>
                              <BsFillTrashFill />
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr className="bg-white border-b hover:bg-gray-50">
                <td colSpan="9" className="px-2 py-2 text-center">
                  Data tidak ditemukan
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-row items-center justify-between px-2">
        <div>
          <span className="text-xs font-poppins-regular">
            Total Rows: {rows} Page: {rows ? page : 0} of {pages}
          </span>
        </div>
        <div>
          <select
            id="showData"
            className="bg-gray-100 border border-gray-300 text-gray-900 text-xs font-poppins-regular rounded-lg focus:ring-blue-500 focus:border-blue-500 py-2"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <nav aria-label="Page navigation example">
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            pageCount={Math.min(10, pages)}
            onPageChange={changePage}
            containerClassName={
              "inline-flex items-center -space-x-px text-xs font-poppins-regular"
            }
            pageLinkClassName={
              "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }
            previousLinkClassName={
              "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
            }
            nextLinkClassName={
              "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
            }
            activeLinkClassName={
              "z-10 px-3 py-2 leading-tight bg-indigo-200 text-indigo-500 cursor-auto font-bold border border-indigo-300 hover:bg-indigo-200 hover:text-indigo-500"
            }
            disabledLinkClassName={
              "z-10 px-3 py-2 leading-tight text-indigo-500 border border-indigo-300 cursor-auto bg-indigo-200 hover:bg-indigo-200 hover:text-indigo-500"
            }
          />
        </nav>
      </div>
    </>
  );
};

export default Table;
