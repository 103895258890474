import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { FcCollaboration } from "react-icons/fc";

const Editor = () => {
  return (
    <div>
      <Header titleHead="Editor" icon={<FcCollaboration />} />
      <Outlet />
    </div>
  );
};

export default Editor;
