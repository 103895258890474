import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";

import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { HiSave, HiArrowNarrowLeft } from "react-icons/hi";

import InputText from "../../components/Form2/InputText";
import ChooseCard from "../../components/ChooseCard";

const FormEdit = () => {
  const [namaLengkap, setNamaLengkap] = useState("");
  const [slugPenulis, setSlugPenulis] = useState("");
  const [initialName, setInitialName] = useState("");
  const [group, setGroup] = useState("");

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    // get refresh token
    checkToken();
    getById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("tokenPL");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const checkGroup = (selected) => {
    setGroup(selected);
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenPL");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getById = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/editor/${id}`);

      setNamaLengkap(response.data.namaLengkap);
      setSlugPenulis(response.data.slugPenulis);
      setInitialName(response.data.initialName);
      setGroup(response.data.group);
    } catch (error) {
      Swal.fire("Error!", error.response.data.message, "error");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosJWT.put(`${API_URL}/editor/${id}`, {
        namaLengkap: namaLengkap,
        slugPenulis: slugPenulis,
        initialName: initialName,
        group: group,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: response.data.message,
        confirmButtonText: "Oke, lihat data!",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (error) {
      Swal.fire("Error!", error.resp.data.message, "error");
    }
  };

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-poppins-semibold text-lg text-indigo-700">
          Edit Editor
        </h2>
        <p className="text-sm font-poppins-light text-gray-400">
          Perubahan data editor, isi informasi data dibawah ini.
        </p>
      </div>
      <form onSubmit={simpan}>
        <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <InputText
              label="Nama Lengkap"
              name="namaLengkap"
              val={namaLengkap}
              set={setNamaLengkap}
              placeholder="Masukkan nama lengkap editor"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-2">
            <InputText
              label="Slug Penulis"
              name="slugPenulis"
              val={slugPenulis}
              set={setSlugPenulis}
              placeholder="Masukkan slug penulis"
            />
            <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
              Ketik slug penulis untuk url penulis
            </p>
          </div>
          <div className="sm:col-span-2">
            <InputText
              label="Nama Inisial"
              name="initialName"
              val={initialName}
              set={setInitialName}
              placeholder="Masukkan isial editor"
            />
            <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
              Ketik nama isial dari editor atau penulis
            </p>
          </div>
          <div className="sm:col-start-1 sm:col-span-2">
            <ChooseCard
              name={group}
              key="Writer"
              value="Writer"
              label="Penulis"
              desc="Pilih ini jika editor yang di tambahkan merupakan penulis"
              onClick={() => checkGroup("Writer")}
            />
          </div>
          <div className="sm:col-span-2">
            <ChooseCard
              name={group}
              key="Editor"
              value="Editor"
              label="Editor"
              desc="Pilih ini jika editor yang di tambahkan merupakan editor
                  tulisan"
              onClick={() => checkGroup("Editor")}
            />
          </div>
        </div>
        <div className="mt-10 flex items-center gap-x-2">
          <button
            type="button"
            className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            onClick={() => navigate(-1)}
          >
            <span className="mr-2">
              <HiArrowNarrowLeft />
            </span>
            <span>Cancel</span>
          </button>
          <button
            type="submit"
            className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
          >
            <span className="mr-2">
              <HiSave />
            </span>
            <span>Simpan Data</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormEdit;
