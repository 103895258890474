import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { HiSave, HiArrowNarrowLeft } from "react-icons/hi";

import InputText from "../../components/Form2/InputText";
import TextArea from "../../components/Form2/TextArea";
import ChooseCard from "../../components/ChooseCard";
import Select from "../../components/Form2/Select";

const FormEdit = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [shortPost, setShortPost] = useState("");
  const [hashTag, setHashTag] = useState("");
  const [statusPost, setStatusPost] = useState("");
  const [headline, setHeadline] = useState("Tidak");
  const [redaksi, setRedaksi] = useState("Tidak");
  const [post, setPost] = useState(() => EditorState.createEmpty());
  const [fotoSumber, setFotoSumber] = useState("");
  const [idKategori, setIdKategori] = useState("");
  const [namaKategori, setNamaKategori] = useState("");
  const [slugKategori, setSlugKategori] = useState("");

  const [allKategori, setAllKategori] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // get refresh token
    checkToken();

    loadKategori();
    getById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("tokenPL");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const checkGroup = (selected) => {
    setStatusPost(selected);
  };

  const handleSelect = async (e) => {
    setIdKategori(e.target.value);
    try {
      const kategori = await axiosJWT.get(
        `${API_URL}/kategori/${e.target.value}`
      );

      setNamaKategori(kategori.data.namaKategori);
      setSlugKategori(kategori.data.slugKategori);
    } catch (error) {
      console.log(error.message);
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenPL");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const loadKategori = async () => {
    try {
      const kategori = await axiosJWT.get(`${API_URL}/kategori-select`);

      setAllKategori(kategori.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getById = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/berita/${id}`);

      setTitle(response.data.title);
      setShortPost(response.data.shortPost);
      setHashTag(response.data.hashTag);
      setStatusPost(response.data.statusPost);
      setHeadline(response.data.headline);
      setRedaksi(response.data.redaksi);
      setFotoSumber(response.data.fotoSumber);
      setIdKategori(response.data.idKategori);
      setNamaKategori(response.data.namaKategori);
      setSlugKategori(response.data.slugKategori);

      const html = response.data.post;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setPost(EditorState.createWithContent(contentState));
      }
    } catch (error) {
      Swal.fire("Error!", error.response.data.message, "error");
    }
  };

  const simpan = (e) => {
    e.preventDefault();

    axiosJWT
      .put(`${API_URL}/berita/${id}`, {
        title: title,
        shortPost: shortPost,
        post: draftToHtml(convertToRaw(post.getCurrentContent())),
        hashTag: hashTag,
        statusPost: statusPost,
        headline: headline,
        redaksi: redaksi,
        fotoSumber: fotoSumber,
        idKategori: idKategori,
        namaKategori: namaKategori,
        slugKategori: slugKategori,
      })
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: "Sukses!",
          text: resp.data.message,
          confirmButtonText: "Oke, lihat data!",
          willClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        Swal.fire("Error!", error.resp.data.message, "error");
      });
  };

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-poppins-semibold text-lg text-indigo-700">
          Edit Berita
        </h2>
        <p className="text-sm font-poppins-light text-gray-400">
          Perubahan berita, isi informasi berita dibawah ini.
        </p>
      </div>
      <form onSubmit={simpan}>
        <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <InputText
              label="Judul Berita"
              name="title"
              val={title}
              set={setTitle}
              placeholder="Masukkan judul berita"
            />
          </div>

          <div className="sm:col-start-1 sm:col-span-4">
            <Select
              label="Kategori"
              name="kategori"
              val={idKategori}
              set={handleSelect}
              item={allKategori}
            />
          </div>

          <div className="sm:col-start-1 sm:col-span-2">
            <ChooseCard
              name={statusPost}
              key="Draft"
              value="Draft"
              label="Draft"
              desc="Pilih ini jika berita belum ingin di publish"
              onClick={() => checkGroup("Draft")}
            />
          </div>
          <div className="sm:col-span-2">
            <ChooseCard
              name={statusPost}
              key="Published"
              value="Published"
              label="Publish"
              desc="Pilih ini jika berita ingin di publish"
              onClick={() => checkGroup("Published")}
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-4">
            <label
              htmlFor={post}
              className="block font-poppins-regular text-xs leading-6 text-gray-400"
            >
              Isi Berita
            </label>
            <Editor
              editorState={post}
              onEditorStateChange={setPost}
              wrapperClassName="border border-slate-200 rounded-lg"
              editorClassName="bg-gray-100 px-4 font-poppins-regular"
              toolbarClassName="rounded-lg"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-4">
            <TextArea
              label="Short Post"
              name="shortPost"
              val={shortPost}
              set={setShortPost}
              rows={4}
              placeholder="Masukkan isi berita singkat"
            />
            <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
              Ketik isi berita yang singkat
            </p>
          </div>
          <div className="sm:col-span-4">
            <InputText
              label="Hash Tag"
              name="hashTag"
              val={hashTag}
              set={setHashTag}
              placeholder="Masukkan hash tag, gunakan koma (,) untuk pemisah setiap tag"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-4">
            <InputText
              label="Sumber Foto"
              name="fotoSumber"
              val={fotoSumber}
              set={setFotoSumber}
              placeholder="Masukkan keterangan asal foto"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-2">
            <label
              htmlFor="headline"
              className="block font-poppins-regular text-xs leading-6 text-gray-400"
            >
              Headline
            </label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="headline"
                id="headline"
                value="Ya"
                className="sr-only peer"
                checked={headline === "Ya" ? true : false}
                onChange={(e) =>
                  e.target.checked ? setHeadline("Ya") : setHeadline("Tidak")
                }
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              <span className="ml-3 text-sm font-poppins-medium text-gray-900">
                {headline}
              </span>
            </label>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="redaksi"
              className="block font-poppins-regular text-xs leading-6 text-gray-400"
            >
              Redaksi
            </label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="redaksi"
                id="redaksi"
                value="Ya"
                className="sr-only peer"
                checked={redaksi === "Ya" ? true : false}
                onChange={(e) =>
                  e.target.checked ? setRedaksi("Ya") : setRedaksi("Tidak")
                }
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              <span className="ml-3 text-sm font-poppins-medium text-gray-900 ">
                {redaksi}
              </span>
            </label>
          </div>
        </div>
        <div className="mt-10 flex items-center gap-x-2">
          <button
            type="button"
            className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            onClick={() => navigate(-1)}
          >
            <span className="mr-2">
              <HiArrowNarrowLeft />
            </span>
            <span>Cancel</span>
          </button>
          <button
            type="submit"
            className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
          >
            <span className="mr-2">
              <HiSave />
            </span>
            <span>Simpan Berita</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormEdit;
