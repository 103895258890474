import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { FcGlobe } from "react-icons/fc";

const Berita = () => {
  return (
    <div>
      <Header titleHead="Berita" icon={<FcGlobe />} />
      <Outlet />
    </div>
  );
};

export default Berita;
