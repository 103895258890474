import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { FcDocument } from "react-icons/fc";

const Page = () => {
  return (
    <div>
      <Header titleHead="Page" icon={<FcDocument />} />
      <Outlet />
    </div>
  );
};

export default Page;
